import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import AlertBox from '../../alert/AlertBox'
import { useTranslation } from 'react-i18next'
import apiClient from '../../../services/apiClient'
import { useProtectedContext } from '../../../contexts/protectedContext'
import { FormHelper, NotificationAlert } from 'components'
import { Controller, useForm } from 'react-hook-form'
import { BaseTextField } from '../../textfield/StyledTextField'
import { useMutation, useQueries } from 'react-query'
import Swal from 'sweetalert2'
import theme from '../../../theme'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import BonusSuperBowlCardChart from './charts/BonusSuperBowlCardChart'

function BonusSuperBowl () {
  const { t } = useTranslation()
  const { participantId } = useProtectedContext()
  const [gamePrediction, setGamePrediction] = useState([])
  const [gameDate, setGameDate] = useState(null)
  const [roundLock, setRoundLock] = useState(true)
  const [teamInPlayoffsAFC, setTeamInPlayoffsAFC] = useState([])
  const [teamInPlayoffsNFC, setTeamInPlayoffsNFC] = useState([])
  const [updated, setUpdated] = useState(false)
  const [errors, setErrors] = useState({})

  const { control, handleSubmit, setValue } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      awayTeam: '',
      homeTeam: '',
    }
  })

  const results = useQueries([
    {
      queryKey: ['fetchTeamInPlayoff'],
      queryFn: () => apiClient.fetchTeamInPlayoff(),
      onSuccess: ({ data }) => {
        setTeamInPlayoffsAFC(data.AFC)
        setTeamInPlayoffsNFC(data.NFC)
      }
    },
    {
      queryKey: ['fetchParticipantBonusSBPrediction'],
      queryFn: () => apiClient.fetchParticipantBonusSBPrediction(participantId),
      onSuccess: ({ data }) => {
        console.log('gamePrediction', data)
        setGamePrediction(data[0])

      }
    }
  ])

  const { mutate: updatePrediction } = useMutation(
    async (gamePrediction) => {
      return await apiClient.updateBonusPrediction(gamePrediction).then(res => {
        if (res.error) throw new Error(res.error)
      });
    },
    {
      onSuccess: () => {
        setUpdated(true)
      },
      onError: (error) => {
        Swal.fire({
          title: t('game.GameUpdatedTitleFail'),
          html: t('game.GameUpdatedFail'),
          icon: 'error',
          timer: 8000,
          timerProgressBar: true,
        })
      }
    }
  );

  const isLoading = results.some(result => result.isLoading)
  const isError = results.some(result => result.data?.error)

  if (isError) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: t('app.ErrorSwal'),
      confirmButtonColor: theme.palette.primary.light,
    })
  }
  //
  useEffect(() => {
    setUpdated(false)
    if (gamePrediction?.game?.date) {
      setValue('awayTeam', teamInPlayoffsAFC?.filter(t => { return t.id === gamePrediction.awayFK })[0])
      setValue('homeTeam', teamInPlayoffsNFC?.filter(t => { return t.id === gamePrediction.homeFK })[0])
      setGameDate(format(new Date(gamePrediction?.game.date), 'EEEE dd MMMM yyyy HH:mm', { locale: fr }))
      setValue('awayScore', gamePrediction.awayScore)
      setValue('homeScore', gamePrediction.homeScore)
      setRoundLock(gamePrediction.game.round.lock)
    }
  }, [teamInPlayoffsAFC, teamInPlayoffsNFC, gamePrediction])

  const handleOnSubmit = (evt) => {
    setUpdated(false)
    let sbPrediction = {
      awayId: evt.awayTeam?.id || 31,
      homeId: evt.homeTeam?.id || 30,
      homeScore: evt.homeScore,
      awayScore: evt.awayScore,
      gameId: gamePrediction.id,
      participantId: participantId,
    }

    if (sbPrediction.awayId === 31 || sbPrediction.homeId === 30) {
      setErrors({ wrongTeam: true })
    } else {
      // if (roundLock == true) {
        Swal.fire({
          title: t('game.GameLockUpdatedTitleFail'),
          html: t('game.GameLockUpdatedFail'),
          icon: 'error',
          timer: 12000,
          timerProgressBar: true,
        });
      // } else {
      //   updatePrediction(gamePrediction);
      // }
      // updatePrediction(sbPrediction)
    }
  }

  const getAbrAFCFromValue = (value) => {
    if (value !== '' && Array.isArray(teamInPlayoffsAFC)) {
      const team = teamInPlayoffsAFC.find(team => team.name === value)?.abbreviation;
      return team ? team.toString().toLowerCase() : 'tbd';
    } else {
      return null;
    }
  };
  const getAbrNFCFromValue = (value) => {
    if (value !== '') {
      const team = teamInPlayoffsNFC.find(team => team.name === value)?.abbreviation
      return team ? team.toString().toLowerCase() : 'tbd';
    } else {
      return null
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="secondary" size={40}/>
      </Backdrop>
      <NotificationAlert openNotification={updated} type="success" message={t('app.SaveSuccess')}/>
      <Container maxWidth="lg" sx={{ pt: 5, pb: 5, minHeight: '86vh' }}>
        <Box>
          <Paper sx={{ p: 2, mb: 5 }} elevation={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Bonus SuperBowl</Typography>
              </Grid>
              <Grid item xs={12}>
                {errors?.wrongTeam && <AlertBox type="error" message={t('game.ErrorSuperBowl')}/>}
                <AlertBox type="warning" message={t('game.WarningLimitBonus')}/>
              </Grid>
              <Grid item xs={12} md={2}/>
              <Grid item xs={12} md={8}>
                <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                  <Card>
                    <CardContent sx={{ pb: 0 }}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {gameDate}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Controller
                            control={control}
                            name="awayTeam"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disabled={roundLock}
                                options={teamInPlayoffsAFC}
                                getOptionLabel={option => option.name || option}
                                onChange={(event, values) => onChange(values)}
                                value={value}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img width="30px"
                                         src={`images/teams/${option.abbreviation.toString().toLowerCase()}.png`}
                                         alt={option.name}
                                    />
                                    {option.name}
                                  </Box>
                                )}
                                renderInput={(params,) => (
                                  <BaseTextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="AFC"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment:
                                        <img width="30px"
                                             src={`images/teams/${getAbrAFCFromValue(params.inputProps.value)}.png`}
                                             alt={params.inputProps.value}
                                        />
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name="awayScore"
                            disabled={roundLock}
                            defaultValue={gamePrediction?.awayScore || 0}
                            rules={{
                              required: true
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <BaseTextField
                                {...field}
                                fullWidth
                                sx={{ mb: 0 }}
                                variant="outlined"
                                label={t('game.Score')}
                                error={error !== undefined}
                                helperText={error ? t(FormHelper.required[error.type]) : ''}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <Controller
                            control={control}
                            name="homeTeam"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disabled={roundLock}
                                options={teamInPlayoffsNFC}
                                getOptionLabel={option => option.name || option}
                                onChange={(event, values) => onChange(values)}
                                value={value}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img width="30px"
                                         src={`images/teams/${option.abbreviation.toString().toLowerCase()}.png`}
                                         alt={option.name}
                                    />
                                    {option.name}
                                  </Box>
                                )}
                                renderInput={(params,) => (
                                  <BaseTextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="NFC"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment:
                                        <img width="30px"
                                             src={`images/teams/${getAbrNFCFromValue(params.inputProps.value)}.png`}
                                             alt={params.inputProps.value}
                                        />
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            control={control}
                            name="homeScore"
                            disabled={roundLock}
                            defaultValue={gamePrediction?.awayScore || 0}
                            rules={{
                              required: true
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <BaseTextField
                                {...field}
                                fullWidth
                                sx={{ mb: 0 }}
                                variant="outlined"
                                label={t('game.Score')}
                                error={error !== undefined}
                                helperText={error ? t(FormHelper.required[error.type]) : ''}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid container>
                        <Grid item xs={4} sx={{ textAlign: 'left', pl: 1 }}>
                          <Typography sx={{ fontSize: 18 }} component="h4">
                            Points : {gamePrediction?.points || 0}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'right' }}>
                          <Button sx={{ fontSize: '1rem', lineHeight: '1.5rem' }} color="primary" variant="contained"
                                  size="small" type="submit" disabled={roundLock}>{t('game.UpdateScore')}</Button>
                        </Grid>
                      </Grid>

                    </CardActions>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        {/*<BonusSuperBowlCardChart/>*/}
      </Container>
    </>
  )
}

export default BonusSuperBowl
