import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid, InputAdornment, Link, Typography} from "@mui/material";
import {FormHelper, myStyles} from "components";
import logo from '../../../asset/images/logo.png'
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useAuthContext} from "../../../contexts/authContext";
import {useNavigate} from "react-router-dom";
import {BaseTextField} from "../../textfield/StyledTextField";
import { auth } from '../../../utils/firebase'
import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
    const { t } = useTranslation()
    const { user, isLoggedIn, setIsLoggedIn } = useAuthContext()
    const navigate = useNavigate()
    const [errors, setErrors] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = () => setShowPassword(!showPassword);

    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    useEffect(() => {
        if (user?.email && isLoggedIn) {
            navigate("/dashboard")
        }else{
            navigate("/login")
        }
    }, [user,navigate, isLoggedIn])

    const handleOnSubmit = async (credentials) => {
        setErrors({});
        setIsProcessing(true);
        await signInWithEmailAndPassword(auth, credentials.email, credentials.password)
          .then(() => {
              setIsLoggedIn(true)
          })
          .catch(() => {
              setErrors((e) => ({ ...e, form: 'auth.InvalidCredentials' })); // Gérer les erreurs selon vos besoins
          })
          .finally(() => {
              setIsProcessing(false);
      });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleOnSubmit(event)
        }
    }

    return (
        <Container maxWidth="lg" sx={myStyles.loginContainer}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={6} sx={myStyles.loginLeftSide}>
                    <div className="login__image">
                        <img src={logo} alt="" className='login__logo' />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}  sx={myStyles.loginRightSide}>
                    <Box display="flex" justifyContent="flex-start"	alignItems="flex-start" flexDirection="column">
                        <Typography variant="h2">{t('auth.SignIn')}</Typography>
                        <Typography gutterBottom>{t('auth.SignInSubtitle')}</Typography>
                    </Box>
                    { errors.form && <span className="errorMsg">{t(errors.form)}</span>}
                    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            rules={{
                                required: true,
                                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={t('auth.Email')}
                                    error={error !== undefined}
                                    helperText={error ? t(FormHelper.email[error.type]) : ""}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            rules={{
                                required: true
                            }}
                            render={({ field: { ...field }, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    label={t('auth.Password')}
                                    fullWidth
                                    type={showPassword? 'text' : 'password'}
                                    variant="outlined"
                                    error={error !== undefined}
                                    helperText={error ? t(FormHelper.required[error.type]) : ""}
                                    onKeyPress={(e) => { e.key === 'Enter' && handleKeyDown(e.target.value) }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                onClick={handleShowPassword}
                                                className="showPassword"
                                                position="end">
                                                {showPassword ? <VisibilityIcon sx={{cursor:'pointer'}} /> : <VisibilityOffIcon sx={{cursor:'pointer'}}/> }
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Typography gutterBottom><Link href="/resetpassword">{t('auth.PasswordForgot')}</Link></Typography>
                        <Button fullWidth variant="contained"  size="large" type="submit" disabled={isProcessing || errors.email}>
                            {t('auth.SignIn')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Login;
