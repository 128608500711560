import axios from 'axios'
import * as Sentry from '@sentry/react'
import { auth } from '../utils/firebase'


class ApiClient {
    constructor(remoteHostUrl) {
        this.remoteHostUrl = remoteHostUrl
        this.token = null
    }

    async getToken() {
        return await auth.currentUser.getIdToken(true).then(function(idToken) {
            return idToken
        })
    }

    async request({ endpoint, method = 'GET', data = {}}) {
        const url = `${this.remoteHostUrl}/${endpoint}`
        this.token = await this.getToken()
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: this.token ? `Bearer ${this.token}` : "",
        }

        try {
            const res = await axios({ url, method, data, headers })
            return { data: res.data, error: null }
        } catch (error) {
            console.error("APIclient.makeRequest.error:")
            console.error({ errorResponse: error.response })
            const message = error?.response?.data?.error?.message
            if (process.env.NODE_ENV === "production") {
                Sentry.captureException(new Error(message));
            }
            return { data: null, error: message || String(error) }
        }
    }
    // ----------------------------------------
    // Games
    // ----------------------------------------
    async fetchAllGames() {
        return await this.request({ endpoint: `game/fetchallgames`, method: `GET` })
    }
    async fetchRoundLock(roundId){
        return await this.request({endpoint: `game/fetchroundlock/${roundId}`, method: `GET`})
    }

    async fetchRoundLockByGameId(gameId){
        return await this.request({endpoint: `game/fetchgamelock/${gameId}`, method: `GET`})
    }

    async fetchStatisticPredictionByRound(roundId){
        return await this.request({endpoint: `prediction/stats/${roundId}`, method: `GET`})
    }
    // ----------------------------------------
    // PARTICIPANTS
    // ----------------------------------------
    async fetchParticipantPrediction(participantId, roundId) {
        return await this.request({
            endpoint: `prediction/round-prediction/${participantId}/${roundId}`,
            method: `GET`,
        });
    }
    async fetchParticipantBonusSBPrediction(participantId) {
        return await this.request({
            endpoint: `bonus/prediction/${participantId}`,
            method: `GET`,
        });
    }
    async fetchParticipantRanking(userid){
        return await this.request({
            endpoint: `ranking/${userid}`,
            method: `GET`,
        });
    }

    // ----------------------------------------
    // PREDICTIONS
    // ----------------------------------------
    async updatePredictionGameScore(gamePrediction) {
        return await this.request({
            endpoint: `prediction/update-prediction`,
            method: `POST`,
            data: {gamePrediction},
        });
    }

    async fetchSuperBowlPredictionParticipation(){
        return await this.request({endpoint: `prediction/fetchsuperbowlpredictionparticipation`, method: `GET`})
    }

    async updateBonusPrediction(gamePrediction) {
        return await this.request({
            endpoint: `bonus/update-prediction`,
            method: `POST`,
            data: {gamePrediction},
        });
    }

    // ----------------------------------------
    // RANKING
    // ----------------------------------------
    async fetchRanking(){
        return await this.request({endpoint: `ranking/`, method: `GET`})
    }
    // ----------------------------------------
    // TEAMS
    // ----------------------------------------
    async fetchTeamInPlayoff(){
        return await this.request({
            endpoint: `team/in-playoff`,
            method: `GET`,
        });
    }
    // ----------------------------------------
    // USER
    // ----------------------------------------
    async fetchUserFromToken() {
        return await this.request({ endpoint: `user/`, method: `GET` })
    }
    async fetchUserFromTokenPassword(token){
        return await this.request({endpoint: `user/fetchUserFromToken/${token}`, method: `GET`})
    }
    async registerUser(participant) {
        return await this.request({
            endpoint: `user/`,
            method: `POST`,
            data: participant,
        });
    }
}


export default new ApiClient(process.env.REACT_APP_REMOTE_HOST_URL)
