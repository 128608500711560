import { createContext, useContext, useEffect, useState } from 'react'
import { Login } from '../components'
import { useAuthContext } from './authContext'
import { Backdrop, CircularProgress } from '@mui/material'
import apiClient from '../services/apiClient'
import { useQuery } from 'react-query'
import Swal from 'sweetalert2'
import theme from '../theme'
import { useTranslation } from 'react-i18next'

const ProtectedContext = createContext(null)

export const ProtectedContextProvider = ({ children }) => {
  const {t} = useTranslation();
  const { isLoggedIn, user } = useAuthContext()
  const [rankingPosition, setRankingPosition] = useState(0)
  const [totalPoints, setTotalPoints] = useState(0)
  const [participantId, setParticipantId] = useState(null)
  const [isLoading, setIsLoading] = useState(true);

  const { refetch: fetchParticipantRanking } = useQuery(
    'fetchParticipantRanking',
    async () => {
      return await apiClient.fetchParticipantRanking(user?.id).then(({ data, error }) => {
        if (error) throw new Error(error)
        setRankingPosition(data.ranking)
        setTotalPoints(data.totalPoints)
        setParticipantId(user?.id)
      })
    },
    {
      enabled: false,
      onError: (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: t('app.ErrorSwal'),
          confirmButtonColor: theme.palette.primary.light,
        })
      }
    }
  )

  useEffect(() => {
    if (isLoggedIn && user?.email) {
      fetchParticipantRanking()
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1300);

    return () => clearTimeout(timer);
  }, [isLoggedIn, isLoading])

  if (isLoading) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit"/>
      </Backdrop>
    );
  }

  if (isLoggedIn && !user?.email) {
    return <Login/>
  }

  const protectedValue = { totalPoints, rankingPosition, participantId }
  if (isLoggedIn && participantId !== null) {
    return (
      <>
        <ProtectedContext.Provider value={protectedValue}>
          {children}
        </ProtectedContext.Provider>
      </>
    )
  } else {
    return (
      <Backdrop open={!isLoggedIn}>
        <CircularProgress color="inherit"/>
      </Backdrop>
    )
  }

}

export const useProtectedContext = () => useContext(ProtectedContext)
