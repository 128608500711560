import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";
import Swal from "sweetalert2";
import {FormHelper} from "../index";
import {Controller, useForm} from "react-hook-form";
import { fr } from 'date-fns/locale'
import {format} from "date-fns";
import { useMutation, useQuery } from 'react-query'
import theme from '../../theme'


function SetGameCard({game, roundLock}) {
    const {t} = useTranslation();

    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const { isLoading, refetch: fetchRoundLockByGameId } = useQuery(
      ['fetchRoundLockByGameId'],
      async () => {
          return await apiClient.fetchRoundLockByGameId(game.id).then(({ data, error }) => {
              if (error) throw new Error(error)
              return data
          })
      },
      {
          enabled: false,
          onError: (error) => {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: t('app.ErrorSwal'),
                  confirmButtonColor: theme.palette.primary.light,
              })
          }
      }
    )

    const { mutate: updatePrediction } = useMutation(
      async (gamePrediction) => {
          return await apiClient.updatePredictionGameScore(gamePrediction).then(res => {
              if (res.error) throw new Error(res.error)
          });
      },
      {
          onSuccess: () => {
              Swal.fire({
                  title: t('game.GameUpdatedTitleSuccess'),
                  icon: 'success',
                  timer: 3000,
                  timerProgressBar: true,
              })
          },
          onError: (error) => {
              Swal.fire({
                  title: t('game.GameUpdatedTitleFail'),
                  html: t('game.GameUpdatedFail'),
                  icon: 'error',
                  timer: 8000,
                  timerProgressBar: true,
              })
          }
      }
    );

    const handleOnSubmit = async (evt) => {
        let gamePrediction = {
            ...evt,
            gameId: game.id,
            participantId: game.predictions[0].userId,
        }
        const { data: roundLock } = await fetchRoundLockByGameId();
        if (roundLock) {
            Swal.fire({
                title: t('game.GameLockUpdatedTitleFail'),
                html: t('game.GameLockUpdatedFail'),
                icon: 'error',
                timer: 12000,
                timerProgressBar: true,
            });
        } else {
            updatePrediction(gamePrediction);
        }
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <Card>
                <CardContent sx={{pb:0}}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {format(new Date(game.date), 'EEEE dd MMMM yyyy HH:mm', {locale: fr})}
                    </Typography>
                        <Grid container>
                            <Grid item xs={5}>
                                <Typography sx={{ fontSize: 14}} component="h6">
                                    {game.awayTeam.name}
                                </Typography>
                                <img width='75px'
                                     src={`images/teams/${game.awayTeam.abbreviation.toString().toLowerCase()}.png`}
                                     alt={game.awayTeam.name}
                                />
                                <Controller
                                    control={control}
                                    name="awayScore"
                                    defaultValue={game.predictions[0]?.awayScore || 0}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            fullWidth
                                            sx={{mt:0, mb:0}}
                                            variant="outlined"
                                            label={game.awayTeam.abbreviation}
                                            error={error !== undefined}
                                            helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{mt:'20px'}}>
                                    <img width='30px'
                                         src={`images/versus.png`}
                                         alt='versus'/>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{ fontSize: 14 }} component="h6">
                                    {game.homeTeam.name}
                                </Typography>
                                <img width='75px'
                                     src={`images/teams/${game.homeTeam.abbreviation.toString().toLowerCase()}.png`}
                                     alt={game.homeName}
                                />
                                <Controller
                                    control={control}
                                    name="homeScore"
                                    defaultValue={game.predictions[0]?.homeScore || 0}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            fullWidth
                                            sx={{mt:0, mb:0}}
                                            variant="outlined"
                                            label={game.homeTeam.abbreviation}
                                            error={error !== undefined}
                                            helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                </CardContent>
                <CardActions >
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography sx={{ fontSize: 18 }} component="h4">
                                Points : { game.predictions[0]?.points || 0 }
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{textAlign:'right'}}>
                            <Button sx={{fontSize: '1rem', lineHeight: '1.5rem'}} color="primary" variant="contained" size="small" type="submit" disabled={roundLock}>{t('game.UpdateScore')}</Button>
                        </Grid>
                    </Grid>

                </CardActions>
            </Card>
            </Box>
        </>
    );
}

export default SetGameCard;
